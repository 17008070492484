<template>
  <div class="expande-horizontal centraliza" style="background: #fff;">
    <v-flex xs12 md8>
      <div class="expande-horizontal column">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 70%;"><span class="fonte-bold" style="font-size: 21pt;">Funcionalidade</span></th>
                <th class="text-left">Orçamento</th>
                <th class="text-left">Gestão Inicial</th>
                <th class="text-left">Gestão Completa</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">Criação de Orçamento</td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Gestão de Vendas</td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Gestão de Produtos</td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Gestão de Clientes</td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Personalização do Orçamento</td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Personalização de Lojas</td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Colaboradores Ilimitados</td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Agendamentos (Entrega, Visita, Email, Ligação, Whatsapp, Etc...)</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Lista de Compras</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Aviso de estoque baixo e envio para lista de compra</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Acompanhamento de Caixa</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Contabilidade de valores do caixa</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Gestão de Contas a pagar e a Receber</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Relatório de contas aprovadas do caixa</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
              <tr>
                <td class="text-left">Permissões Personalizadas</td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="red">mdi-close</v-icon> </td>
                <td class="text-left"> <v-icon color="green">mdi-check</v-icon> </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-flex>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        plans: []
      }
    }
  }
</script>